<template>
  <v-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Edit Question' : 'প্রশ্ন এডিট করুন'"
      class="mt-5"
    ></page-title>

    <single-question-edit
      :question-id="questionId"
      @submitted="goBack"
      @cancel="goBack"
    ></single-question-edit>
  </v-container>
</template>

<script>
import SingleQuestionEdit from "#ecf/question-bank/components/SingleQuestion/Edit";
export default {
  name: "SingleQuestionEditPage",
  components: { SingleQuestionEdit },
  props: {
    questionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      question: undefined,
      editing: false,
      loadingQuestion: false
    };
  },
  computed: {},
  methods: {
    async goBack() {
      await this.$router.replace({ name: "question-list" });
    }
  }
};
</script>

<style></style>
