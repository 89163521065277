<template>
  <lazy-load
    :item="
      !loadingQuestion[questionId] && question.questionDetails.questionType
    "
  >
    <single-question-form
      :course-id="courseId"
      :question="question"
      :is-valid.sync="isValid"
      :submitting="updatingQuestion"
      @input="(v) => $set(question, v.name, v.value)"
      @updateqt="updateType()"
      @submit="update(questionId, question)"
      @cancel="$emit('cancel')"
    ></single-question-form>
  </lazy-load>
</template>

<script>
import SingleQuestionForm from "#ecf/question-bank/components/SingleQuestion/Form";
import { QuestionProvider, QuestionUpdater, EquationFinder } from "#ecf/question-bank/mixins";
import { cloneDeep } from "lodash";

export default {
  name: "SingleQuestionEdit",
  components: { SingleQuestionForm },
  mixins: [QuestionUpdater, QuestionProvider, EquationFinder],
  inject: ["$courseOwnerId", "$courseId"],
  props: {
    courseId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      question: {
        questionDetails: {},
        answers: [],
      },
      isValid: true,
    };
  },
  computed: {
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
  },
  async created() {
    const question = await this.fetchQuestion(this.questionId);
    question.questionDetails.text = await this.findEquationsFromQuestionText(question.questionDetails.text, question.question_id);
    this.question = cloneDeep(question);
  },
  methods: {
    updateType() {
      this.question.answers = [
        {
          text: undefined,
          is_correct: false,
          image: { imageURL: undefined, file: undefined },
          explanation: undefined,
        },
      ];
      this.question.answerPreference = { word_limit: 100 };
    },
  },
};
</script>

<style></style>
