var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Edit Question" : "প্রশ্ন এডিট করুন",
        },
      }),
      _c("single-question-edit", {
        attrs: { "question-id": _vm.questionId },
        on: { submitted: _vm.goBack, cancel: _vm.goBack },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }